// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portofolio-laminar-js": () => import("./../src/pages/portofolio/laminar.js" /* webpackChunkName: "component---src-pages-portofolio-laminar-js" */),
  "component---src-pages-portofolio-sotonite-js": () => import("./../src/pages/portofolio/sotonite.js" /* webpackChunkName: "component---src-pages-portofolio-sotonite-js" */),
  "component---src-pages-portofolio-studysmart-js": () => import("./../src/pages/portofolio/studysmart.js" /* webpackChunkName: "component---src-pages-portofolio-studysmart-js" */),
  "component---src-pages-portofolio-tst-js": () => import("./../src/pages/portofolio/tst.js" /* webpackChunkName: "component---src-pages-portofolio-tst-js" */),
  "component---src-pages-skills-js": () => import("./../src/pages/skills.js" /* webpackChunkName: "component---src-pages-skills-js" */)
}

