module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-115113875-2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"lyg6rla"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Adrian Manduc | Fullstack developer","description":"A GatsbyJS stater with Advanced design in mind.","start_url":"","background_color":"#fff","theme_color":"#fff","display":"minimal-ui","icon":"static/favicon/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"5b2875c3fbf0208e50092594f6bc9e93"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
